import theme from "./../../styles/theme.scss";

const
	merge = require("deepmerge"),
	defaultButton = () => {
		return {
			backgroundColor: theme.button_background_color,
			border: theme.button_border,
			borderRadius: theme.button_border_radius,
			color: theme.button_color,
			fontFamily: theme.button_font_family,
			fontSize: theme.button_font_size,
			letterSpacing: theme.button_font_letter_spacing,
			lineHeight: theme.button_line_height,
			margin: theme.button_margin,
			minHeight: theme.button_min_height,
			minWidth: theme.button_min_width,
			padding: theme.button_padding,
			textAlign: theme.button_text_align,
			textDecoration: theme.button_text_decoration,
			textTransform: theme.button_text_transform,
			"@media (hover: none)": {
				"&:hover": {
					backgroundColor: theme.button_background_color
				}
			},
			"@media (hover: hover)": {
				"&:hover": {
					backgroundColor: theme.button_background_color_hover,
					color: theme.button_color_hover,
					textDecoration: theme.button_text_decoration
				}
			},
			"&:active,&:focus-visible": {
				backgroundColor: theme.button_background_color_focus,
				borderColor: theme.button_color_focus,
				boxShadow: "unset",
				color: theme.button_color_focus,
				outline: "unset",
				outlineOffset: "unset",
				textDecoration: theme.button_text_decoration
			},
			"&:disabled": {
				backgroundColor: theme.button_background_color,
				color: theme.button_color
			}
		};
	},
	primaryButton = () => {
		return merge(
			defaultButton(),
			{
				backgroundColor: theme.button_primary_background_color,
				borderColor: theme.button_primary_border_color,
				color: theme.button_primary_color,
				"@media (hover: none)": {
					"&:hover": {
						backgroundColor: theme.button_primary_background_color,
						borderColor: theme.button_primary_border_color,
						color: theme.button_primary_color
					}
				},
				"@media (hover: hover)": {
					"&:hover": {
						backgroundColor: theme.button_primary_background_color_hover,
						borderColor: theme.button_primary_border_color_hover,
						color: theme.button_primary_color_hover
					}
				},
				"&:active,&:focus-visible": {
					backgroundColor: theme.button_primary_background_color_focus,
					borderColor: theme.button_primary_border_color_focus,
					color: theme.button_primary_color_focus
				},
				"&:disabled": {
					backgroundColor: theme.button_primary_background_color_disabled,
					borderColor: theme.button_primary_border_color_disabled,
					color: theme.button_primary_color_disabled
				}
			}
		);
	},
	secondaryButton = () => {
		return merge(
			defaultButton(),
			{
				backgroundColor: theme.button_secondary_background_color,
				borderColor: theme.button_secondary_border_color,
				color: theme.button_secondary_color,
				"@media (hover: none)": {
					"&:hover": {
						backgroundColor: theme.button_secondary_background_color,
						borderColor: theme.button_secondary_border_color,
						color: theme.button_secondary_color
					}
				},
				"@media (hover: hover)": {
					"&:hover": {
						backgroundColor: theme.button_secondary_background_color_hover,
						borderColor: theme.button_secondary_border_color_hover,
						color: theme.button_secondary_color_hover
					}
				},
				"&:active,&:focus-visible": {
					backgroundColor: theme.button_secondary_background_color_focus,
					borderColor: theme.button_secondary_border_color_focus,
					color: theme.button_secondary_color_focus
				},
				"&:disabled": {
					backgroundColor: theme.button_secondary_background_color_disabled,
					borderColor: theme.button_secondary_border_color_disabled,
					color: theme.button_secondary_color_disabled
				}
			}
		);
	},
	outlineButton = () => {
		return merge(
			defaultButton(),
			{
				backgroundColor: theme.button_outline_background_color,
				borderColor: theme.button_outline_border_color,
				color: theme.button_outline_color,
				"@media (hover: none)": {
					"&:hover": {
						backgroundColor: theme.button_outline_background_color,
						borderColor: theme.button_outline_border_color,
						color: theme.button_outline_color
					}
				},
				"@media (hover: hover)": {
					"&:hover": {
						backgroundColor: theme.button_outline_background_color_hover,
						borderColor: theme.button_outline_border_color_hover,
						color: theme.button_outline_color_hover
					}
				},
				"&:active,&:focus-visible": {
					backgroundColor: theme.button_outline_background_color_focus,
					borderColor: theme.button_outline_border_color_focus,
					color: theme.button_outline_color_focus
				},
				"&:disabled": {
					backgroundColor: theme.button_outline_background_color_disabled,
					borderColor: theme.button_outline_border_color_disabled,
					color: theme.button_outline_color_disabled
				}
			}
		);
	},
	tertiaryButton = () => {
		return merge(
			defaultButton(),
			{
				backgroundColor: theme.button_tertiary_background_color,
				borderColor: theme.button_tertiary_border_color,
				color: theme.button_tertiary_color,
				"@media (hover: none)": {
					"&:hover": {
						backgroundColor: theme.button_tertiary_background_color,
						borderColor: theme.button_tertiary_border_color,
						color: theme.button_tertiary_color
					}
				},
				"@media (hover: hover)": {
					"&:hover": {
						backgroundColor: theme.button_tertiary_background_color_hover,
						borderColor: theme.button_tertiary_border_color_hover,
						color: theme.button_tertiary_color_hover
					}
				},
				"&:active,&:focus-visible": {
					backgroundColor: theme.button_tertiary_background_color_focus,
					borderColor: theme.button_tertiary_border_color_focus,
					color: theme.button_tertiary_color_focus
				},
				"&:disabled": {
					backgroundColor: theme.button_tertiary_background_color_disabled,
					borderColor: theme.button_tertiary_border_color_disabled,
					color: theme.button_tertiary_color_disabled
				}
			}
		);
	},
	ctalink = () => {
		return {
			alignItems: theme.button_cta_link_align_items,
			background: theme.button_cta_link_background_color,
			borderRadius: "0",
			color: theme.button_cta_link_color,
			display: theme.button_cta_link_display,
			fontFamily: theme.button_cta_link_font_family,
			fontSize: theme.button_cta_link_font_size,
			justifyContent: theme.button_cta_link_justify_content,
			letterSpacing: theme.button_cta_link_letter_spacing,
			lineHeight: theme.button_cta_link_line_height,
			margin: theme.button_cta_link_margin,
			padding: theme.button_cta_link_padding,
			textAlign: theme.button_cta_link_text_align,
			textDecoration: theme.button_cta_link_text_decoration,
			textTransform: theme.button_cta_link_text_transform,
			"@media (hover: none)": {
				"&:hover": {
					background: theme.button_cta_link_background_color
				}
			},
			"@media (hover: hover)": {
				"&:hover": {
					background: theme.button_cta_link_background_color,
					fontFamily: theme.button_cta_link_font_family_hover,
					textDecoration: theme.button_cta_link_text_decoration_hover
				}
			},
			"&:active,&:focus-visible": {
				background: theme.button_cta_link_background_color,
				fontFamily: theme.button_cta_link_font_family_hover,
				textDecoration: theme.button_cta_link_text_decoration_hover
			}
		};
	},
	ctalinkWithArrow = () => {
		return merge(
			ctalink(),
			{
				"&::after": {
					backgroundImage: theme.button_cta_link_with_arrow_background_image,
					backgroundPosition: theme.button_cta_link_with_arrow_background_position,
					backgrounRepeat: theme.button_cta_link_with_arrow_background_repeat,
					backgroundSize: theme.button_cta_link_with_arrow_background_size,
					content: theme.button_cta_link_with_arrow_content,
					display: theme.button_cta_link_with_arrow_display,
					height: theme.button_cta_link_with_arrow_height,
					margin: theme.button_cta_link_with_arrow_margin,
					width: theme.button_cta_link_with_arrow_width
				}
			}
		);
	};

export {
	defaultButton,
	secondaryButton,
	primaryButton,
	outlineButton,
	tertiaryButton,
	ctalink,
	ctalinkWithArrow
};